/* 悬浮窗*/

<template>
  <div :class="$style.floatingWindowWp" ref="suspend" v-if="visible">
    <!-- 小球 -->
    <img
      :class="`${$style.dragBall}`"
      :src="ball_link"
      @touchstart.stop="handleTouchStart"
      @touchend.stop="handleTouchEnd"
      @touchmove.stop="handleTouchMove"
    />
  </div>
</template>

<script>
import { get_float_ball_info } from '@/api/common';
import {
  reportData,
} from '@/api/common.js'

export default {
  name: '',
  data() {
    return {
      ball_link: '',
      ball_id: '',
      redir_link: '',
      // 拖动相关
      oW: 0,
      oH: 0,
      oLeft: 0,
      oTop: 0,
      // ------
      isClick: true,
      active: false,
      positionLeft: true,
      positionTopLeft: false,
      positionBottomLeft: false,
      positionBottomRight: false,
      showActFlag: false,
      resetTimer: null,
      bubbleVisible: false,
      hideHintVisible: false,
      isDrag: false,
      singleTimeout: null,
      visible: false,
      suspend: null,
    };
  },
  mounted() {
    this.getFloatBallInfo();
  },
  methods: {
    getFloatBallInfo() {
      get_float_ball_info(this.$route.meta.suspend).then(res => {
        if (res.data.icon) {
          this.ball_link = res.data.icon;
          this.redir_link = res.data.url;
          this.ball_id = res.data.id;
          this.visible = true;
          setTimeout(() => {
            this.initSuspend();
          }, 100);
        } else {
          this.visible = false;
        }
      });
    },
    close() {
      this.bubbleContentShow = false;
    },
    initSuspend() {
      // dom节点
      this.suspend = this.$refs.suspend;
      // 窗口宽度
      this.windowWidth = document.body.clientWidth;
      // 窗口高度
      this.windowHeight = document.body.clientHeight;
    },
    handleTouchStart(e) {
      const touches = e.touches[0];
      this.oW = touches.clientX - this.suspend.offsetLeft;
      this.oH = touches.clientY - this.suspend.offsetTop;
    },
    handleTouchMove(e) {
      this.hideHintVisible = true;
      this.isClick = false;
      this.isDrag = true;

      const touches = e.touches[0];
      let oLeft = touches.clientX - this.oW;
      let oTop = touches.clientY - this.oH;
      this.positionLeft = oLeft <= this.windowWidth / 2;
      this.positionTopLeft = oTop > this.windowHeight / 2 && oLeft > this.windowWidth / 2;
      this.positionBottomLeft = oTop <= this.windowHeight / 2 && oLeft > this.windowWidth / 2;
      this.positionBottomRight = oTop <= this.windowHeight / 2 && oLeft <= this.windowWidth / 2;
      if (oLeft < 0) {
        oLeft = 0;
      } else if (oLeft > document.documentElement.clientWidth - this.suspend.offsetWidth) {
        oLeft = document.documentElement.clientWidth - this.suspend.offsetWidth;
      }
      if (oTop < 0) {
        oTop = 0 + 'px';
      } else if (oTop > document.documentElement.clientHeight - this.suspend.offsetHeight) {
        oTop = document.documentElement.clientHeight - this.suspend.offsetHeight + 'PX';
      }
      this.oLeft = oLeft;
      this.oTop = oTop;
      this.suspend.style.left = oLeft + 'PX';
      this.suspend.style.top = oTop + 'PX';
    },
    handleTouchEnd(e) {
      this.isDrag = false;
      this.hideHintVisible = false;
      if (this.isClick) {
        this.handleClick()
      }
      this.isClick = true;
    },
    handleClick() {
      this.EVENT_TRACK(1);
      setTimeout(() => {
        window.open(this.redir_link, '_blank');
      }, 500);
    },
    EVENT_TRACK (data_type, params = {}) {
      reportData({
        type: 23, // 本活动
        data_type,
        extend: `${this.ball_id}-${this.$route.meta.suspend}`,
      })
    },
  },
};
</script>
<style lang="scss">

</style>

<style lang="scss" module>
.floatingWindowWp {
  position: fixed;
  right: 0;
  bottom: 120px;
  z-index: 10;
  display: inline-block;
  width: 45px;
  height: 45px;
  touch-action: none; /* 阻止默认触摸行为 */
  &.linear {
    transition: all 250ms linear;
  }
  .dragBall {
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    z-index: 1;
  }
}
</style>
