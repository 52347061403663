import Vue from 'vue';
import Router from 'vue-router';
import { setStorage, getStorage } from '../utils/tool';
import { is_Auth, wx_login } from '@/api/auth';
import { APP_ID } from '@/config';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/preview',
      name: 'preview',
      component: () => import('@/views/preview/index.vue'),
      children: [
        {
          path: '996',
          name: 'preview.996',
          component: () => import('@/views/preview/996_new.vue'),
          meta: { title: '变态猫—996游戏推荐福利官', suspend: 1 },
        },
        {
          path: 'gift',
          name: 'preview.gift',
          component: () => import('@/views/preview/gift.vue'),
          meta: { title: '变态猫—996游戏推荐福利官', suspend: 2 },
        },
        {
          path: 'mine',
          name: 'preview.mine',
          component: () => import('@/views/preview/mine.vue'),
          meta: { title: '变态猫—996游戏推荐福利官' },
        },
        {
          path: 'detail',
          name: 'preview.detail',
          component: () => import('@/views/preview/detail.vue'),
          meta: { title: '变态猫—996游戏推荐福利官' },
        },
      ],
    },
    // 客服链接
    {
      path: '/service',
      name: 'service',
      component: () => import('@/views/service/index.vue'),
      meta: { title: '变态猫—996游戏推荐福利官' },
    },
    // 签到活动
    {
      path: '/signAct',
      name: 'signAct',
      component: () => import('@/views/signAct/index.vue'),
      meta: { needAuth: true, suspend: 3 },
    },
     // 代金券活动
     {
      path: '/voucherAct',
      name: 'voucherAct',
      component: () => import('@/views/voucherAct/index.vue'),
      meta: { needAuth: true, title: '代金券活动', keepAlive: true },
    },
    // 代金券活动 - 领取记录
    {
      path: '/voucherRecord',
      name: 'voucherRecord',
      component: () => import('@/views/voucherAct/record.vue'),
      meta: { needAuth: true, title: '领取记录' },
    },
    {
      path: '/limitedAct',
      name: 'limitedAct',
      component: () => import('@/views/limitedAct/index.vue'),
      meta: { needAuth: true, suspend: 4 },
    },
    {
      path: '/drawLot',
      name: 'drawLot',
      component: () => import('@/views/activities/drawLot/index.vue'),
      meta: { needAuth: true },
    },
  ],
});

const handleQuery = query => {
  let arr = [];
  for (let it in query) {
    arr.push(`${it}=${query[it]}`);
  }
  arr = arr.join('_AND_');
  return arr;
};

const composeQuery = query => {
  let arr = query.split('_AND_');
  return `?${arr.join('&')}`;
};


router.beforeEach(async (to, from, next) => {
  const redirect_url = encodeURIComponent(window.location.href);

  if (!to.meta.needAuth) {
    next();
    return
  }
  
  const token = localStorage.getItem('996token');
  console.log(109, token)
  if (token) {
    try {
      await is_Auth();  
      next();
    } catch (error) {  
      localStorage.clear();
      const state = handleQuery(to.query)
      localStorage.setItem('toPath', to.path);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
    }
    next();
    return;
  } else if (isAuthorizationRedirectUrl()) {
    const { code, state } = handleLocationSearch();
    const res = await wx_login({ code });
    localStorage.setItem('996token', res.data.token);
    const toPath = localStorage.getItem('toPath')
    const _url = window.location.protocol + '//' + window.location.hostname + '/#' + toPath + '?' + state;
    window.location.replace(_url);
  } else {
    const state = handleQuery(to.query)
    localStorage.setItem('toPath', to.path);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
  }
  
});

// 判断是否为微信授权页跳转链接
const isAuthorizationRedirectUrl = () => {
  try {
    const search = decodeURIComponent(window.location.search);
    const flag = search.indexOf('code') === -1 ? false : true;
    return flag;
  } catch (error) {
    console.log('授权页', error);
    return false;
  }
};

// 处理微信授权页跳转链接, 获取code
const handleLocationSearch = () => {
  try {
    let search = decodeURIComponent(window.location.search);
    search = search.slice(1).split('&state=');
    let obj = {
      code: search[0].split('=')[1],
      state: search[1],
    };
    return obj;
  } catch (e) {
    console.log('auth', e);
    return {};
  }
};


export default router;
