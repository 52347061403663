<template>
  <div id="app">
    <router-view />
    <SuspendBall v-if="suspendShow" ref="suspendBall"/>
  </div>
</template>

<script>
import SuspendBall from '@/components/SuspendBall/index.vue';
export default {
  name: 'App',
  components: {
    SuspendBall
  },
  computed: {
    suspendShow() {
      return this.$route.meta.suspend;
    },
  },
  watch: {
    $route(to, from) {
      if (to.meta.suspend) {
        this.$nextTick(() => {
          this.$refs.suspendBall.getFloatBallInfo();
        });
      }
    },
  },
};
</script>

<style>
</style>
