import axios from 'axios';
import { API_URL } from '@/config/index';
import { Toast } from 'vant';

const setRlaTokenInHeader = (config) => {
  const _KEY = 'rla_token_map'
  const tokenSet = JSON.parse(localStorage.getItem(_KEY) || '{}')
  Object.keys(tokenSet).forEach(k => {
    if (k.startsWith('rlaToken')) {
      config.headers[k] = tokenSet[k]
    }
  })
}

const setAnyTokenInHeader = (config, _KEY, keyPrefix) => {
  const tokenSet = JSON.parse(localStorage.getItem(_KEY) || '{}')
  Object.keys(tokenSet).forEach(k => {
    if (k.startsWith(keyPrefix)) {
      config.headers[k] = tokenSet[k]
    }
  })
}

class HttpRequest {
  getInsideConfig(options) {
    const { needRlaToken, addToken } = options
    const config = {
      baseURL: API_URL,
      headers: {
        token: localStorage.getItem('996token'),
      },
    };
    if (needRlaToken) {
      // rlaToken 可能有多个不同的 key
      setRlaTokenInHeader(config)
    }
    if (addToken) {
      setAnyTokenInHeader(config, addToken._KEY, addToken.keyPrefix)
    }
    return config;
  }
  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        const { data = {}, status, config, message } = res;
        if (status !== 200) return;
        if (data.code === 1) {
          return data;
        } else if (data.code === 555) {
          // token过期特殊状态码，token过期，则清除本地缓存的token, 跳转路由的时候进行新token的获取
          localStorage.clear();
          // 强制刷新页面走beforEach()钩子函数
          window.location.reload();
          return Promise.reject(data);
        } else if (data.code > 1000) {
          // 防刷提示
          Toast.fail('系统繁忙，请重试！');
          return Promise.reject(data);
        } else if (config.showToast) {
          const msg = message || data.message
          if (msg) {
            Toast.fail(msg)
          }
          return Promise.reject(data);
        } else {
          return Promise.reject(data);
        }
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let errorInfo = error.response;
        if (!errorInfo) {
          const {
            request: { status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            status,
            request: {
              responseURL: config.url,
            },
          };
        }
        return Promise.reject(errorInfo);
      },
    );
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(options), options);
    this.interceptors(instance);
    return instance(options);
  }
}
const axiosRequest = new HttpRequest();
function request(data) {
  try {
    const r = axiosRequest.request(data);
    return r;
  } catch (error) {
    console.log(error, '请求错误');
  }
}

export default request;
