import Vue from 'vue';
import App from './App.vue';
import router from './router';

import 'vant/lib/index.css'; // 引入 vant 样式
import './styles/index.scss'; // 引入全局样式
import './utils/rem.js'; // 引入 rem
import VueLuckyCanvas from '@lucky-canvas/vue'
import setupDirective from '@/directive/setup'

Vue.config.productionTip = false;
Vue.use(VueLuckyCanvas)
setupDirective(Vue)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
