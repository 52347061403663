export const serialize = (val) => {
  return JSON.stringify(val);
};

export const deserialize = (val) => {
  if (typeof val !== 'string') {
    return undefined;
  }
  try {
    return JSON.parse(val);
  } catch (e) {
    return val || undefined;
  }
};

export const setStorage = (key, val) => {
  localStorage.setItem(key, serialize(val));
};

export const getStorage = (key) => {
  return deserialize(localStorage.getItem(key));
};

// 生成 uuid
export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
