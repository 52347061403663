import request from '@/utils/request.js';

// 绑定账号 - 发送验证码
export const on_send_valid_code = (data) =>
  request({ url: '/v1/game_account/send_phone_code', method: 'post', data });

// 绑定账号 - 验证码验证 & 绑定
export const on_code_verify_bind_account = (data) =>
  request({ url: '/v1/game_account/phone_bind', method: 'post', data, showToast: true });

// 绑定账号 - 验证码验证 & 绑定 - 注册抽奖
export const on_code_verify_bind_account_lucky = (data) =>
  request({ url: `/v1/rla/bind_game_account_by_phone/${data.id}`, method: 'post', data, showToast: true });

// 礼包中心获取绑定信息
export const on_get_bind_info = () =>
  request({ url: '/v1/user/bind_info', method: 'post' });

// 绑定账号 - 验证码验证 & 绑定 - 代金券活动
export const on_code_verify_bind_account_voucher = (data) =>
  request({ url: `/v1/voucher/bind_game_account_by_phone/${data.id}`, method: 'post', data, showToast: true });
  
// 绑定账号 - 账号密码 - 代金券活动
export const on_password_verify_bind_account_voucher = (data) =>
  request({ url: `/v1/voucher/bind_game_account/${data.id}`, method: 'post', data, showToast: true })

// 获取悬浮球信息
export const get_float_ball_info = (location) => request({ url: '/v1/suspended_ball/config', method: 'get', params: { location } });

// 数据上报
export const reportData = (data) => request({ url: '/v1/report_data/async', method: 'post', data });
  