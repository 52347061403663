// 适应不同屏幕,动态调整html的fontSize

(function (win) {
  // html的字体基准大小
  let baseSize = 75;
  // 动态设置 html字体大小
  function setFontSize() {
    const html = document.documentElement;
    // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
    let scale = html.clientWidth / 375;
    if (window.orientation == 180 || window.orientation == 0) {
      scale = html.clientWidth / 375;
    }
    if (window.orientation == 90 || window.orientation == -90) {
      scale = html.clientWidth / 667;
    }
    // 设置页面根节点字体大小
    html.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
  }
  setFontSize();
  window.onload = function () {
    setFontSize();
  };
  window.onresize = function () {
    setFontSize();
  };
  window.onorientationchange = function () {
    setFontSize();
  };
  window.changeBaseSize = function (size) {
    baseSize = size;
    setFontSize();
  };
  window.resetBaseSize = function () {
    baseSize = 75;
    setFontSize();
  };
})(window);
