import Clipboard from "clipboard";
import { uuidv4 } from '@/utils/tool'
import { Toast } from 'vant';
export default {
  name: 'clipboard',
  inserted (el, binding) {
    const randomId = 'RANDOM_' + uuidv4().slice(0, 8)
    el.id = randomId
    //  || el.innerText
    const copyText = binding.value
    if (copyText) {
      // 礼包码为空 不去设置 data-clipboard-text
      el.setAttribute('data-clipboard-text', copyText)
    }
    const clipboard = new Clipboard('#' + randomId)
    clipboard.on('success', function(e) {  
      Toast('已成功复制到剪贴板')
    }) 
    // data-clipboard-text 空值则走到 error
    clipboard.on('error', function(e) {  
      Toast('礼包码生成错误，可返回公众号菜单联系客服')
    })  
  },
}
