// 获取基础api地址
const getApiBaseurl = function () {
  // 测试环境
  if (process.env.VUE_APP_IS_TEST) {
    return `//heziwebsitetest.hzwxbz999.cn/api`;
  }
  switch (window.location.hostname) {
    // 悦玩
    case 'heziwebsitetest.hzwxbz999.cn':
    case 'heitest.xxinyou.cn':
      return `//heziwebsite-api-test.hzwxbz999.cn/api`;
    case 'heziwebsitepre.hzwxbz999.cn':
    case 'heipre.xxinyou.cn':
      return `//heziwebsite-api-pre.hzwxbz999.cn/api`;
    case 'heziwebsite.hzwxbz2.cn':
    case 'hei.xxinyou.cn':
      return `//heziwebsite-api.hzwxbz2.cn/api`;
    // 默认线上悦玩地址
    default:
      return `//heziwebsite-api.hzwxbz2.cn/api`;
  }
};

// cdn基础地址
export const CDN_URL = '//static.hzwxbz3.cn/Lianyun/font_end/HeiMiaoPlatform';
// 通用接口基础路径
export const API_URL = getApiBaseurl();

let appId = 'wx4e30ec1302cc8709';
if (['heziwebsitepre.hzwxbz999.cn', 'heziwebsitetest.hzwxbz999.cn'].includes(window.location.hostname)) {
  appId = 'wx257c0cc9d1d651bb';
}
export const APP_ID = appId;
